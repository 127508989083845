import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CartComponent } from './cart.component';
import { CheckboxModule } from 'src/app/components/form/checkbox/checkbox.module';
import { InputErrorMsgModule } from 'src/app/components/form/error/input-error-msg/input-error-msg.module';
import { RadiobuttonModule } from 'src/app/components/form/radiobutton/radiobutton.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'src/app/components/form/button/button.module';
import { LoginRegisterContentModule } from 'src/app/layouts/_partials/modals/login-register-modal/_partials/login-register-content/login-register-content.module';
import { RouterModule } from '@angular/router';
import { TicketModule } from './_partials/ticket/ticket.module';
import { WalletModule } from 'src/app/components/assets/icons/wallet/wallet.module';
import { SeasonPassModule } from './_partials/season-pass/season-pass.module';
import { BackModule } from 'src/app/components/assets/icons/back/back.module';
import { LinkButtonModule } from 'src/app/components/common/link-button/link-button.module';
import { AddonComponent } from './_partials/addon/addon.component';
import { TranslateModule } from '@ngx-translate/core';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { FlexTicketComponent } from './_partials/flex-ticket/flex-ticket.component';

@NgModule({
  declarations: [CartComponent ],
  imports: [
    CommonModule,
    CheckboxModule,
    InputErrorMsgModule,
    RadiobuttonModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    LoginRegisterContentModule,
    RouterModule,
    TicketModule,
    WalletModule,
    SeasonPassModule,
    BackModule,
    TranslateModule,
    LocalizeRouterModule,
    FlexTicketComponent,
    LinkButtonModule,
    AddonComponent,
  ],
  exports : [
    CartComponent,
  ]
})
export class CartModule { }
