<li  class="grid grid-cols-[80%_20%] sm:flex sm:flex-col p-5 relative border-t border-t-[#d0d0d0]">
    <button type="button" [ngClass]="{'cursor-progress opacity-30' : isRequesting}" class="absolute top-6 right-10 outline-none appearance-none border-0" (click)="removeSeat(ticket.seat_id, fixtureId)">
        <app-x></app-x>   
    </button>
    <div>
        <p class="text-sm font-bold">{{ ticket.title }}</p>
        <p class="text-[13px]">{{ ticket.price }}</p>
        <p class="text-[13px]">{{ ticket.type }} / <button type="button" (click)="showTicketTypeEditModal()" class="font-bold text-[var(--red)]">{{ 'ticket.edit-ticket-type' | translate }}</button></p>
    </div>
    <!--<div class="flex justify-center items-center sm:mt-3" *ngIf="!ticket.details.personal_details.full">   
        <button *ngIf="ticket.requirements.personal_details.length" type="button" class="w-full font-bold text-[13px] text-[var(--red)]" (click)="showPersonalDetailModal()">
            {{ ticket.need_to_fill_details ?  ('ticket.ticket-owner-required' | translate) : ('ticket.set-ticket-owner' | translate) }}
        </button>
    </div>-->
    <!--<div class="flex justify-between items-center sm:mt-3" *ngIf="ticket.details.personal_details.full">   
        <p class="text-[13px]">{{ ticket.details.personal_details.short }}</p>
        <button type="button" class="font-bold text-[13px] text-[var(--red)]" (click)="showPersonalDetailModal()">{{ 'ticket.edit-ticket-owner' | translate }}</button>
    </div>-->
    <div class="col-span-full">
        <app-ticket-personal-details 
            [ticketId]="ticket.seat_id" 
            [key]="key"
            [fixtureId]="fixtureId"
            [inputCountries]="countries"
        />
    </div>
</li>
