<input 
    [required]="required" 
    [ngModel]="value" 
    [type]="type" 
    [name]="name"
    [disabled]="disabled"
    (ngModelChange)="inputValueChangedFn($event)" 
    [placeholder]="placeholder" 
    (blur)="onBlur()" 
    [class]="'bg-[#f3f3f3] disabled:opacity-50 disabled:cursor-not-allowed p-3 placeholder-[#919191] color-black w-full rounded-[5px] outline-none border border-transparent' + customCssClasses">
