<div class="container">

    <form [formGroup]="checkoutForm" (ngSubmit)="onSubmit()" [ngClass]="{'is-requesting' : isRequesting}">
        <div class="flex justify-between mt-14 md:flex-col md:gap-4">
            <div class="w-[67%] md:w-full">
                <div class="flex gap-3 items-center" [ngClass]="{'mb-5' : mapOnly}">
                    <button (click)="navigateBack()" type="button">
                        <app-back-icon *ngIf="mapOnly" />
                    </button>

                    <div class="flex gap-4">
                        <a [routerLink]="['/cart'] | localize" 
                            (click)="reloadRoute('/cart')"
                            class="text-white text-[15px] font-bold mb-5 flex hover:opacity-100 transition-all" 
                            [ngClass]="{'mb-0' : mapOnly, 'opacity-50' : step == 2}">{{ 'cart.cart-content' | translate }}</a>

                        <span class="text-white font-black" *ngIf="lastStep > 1">|</span>

                        <span (click)="hasAvailableAddons() ? toNextStep() : null"
                            class="text-white text-[15px] font-bold mb-5 flex cursor-pointer hover:opacity-100 transition-all"
                            *ngIf="lastStep > 1"
                            [ngClass]="{'mb-0' : mapOnly, 'opacity-50' : step == 1, '!opacity-30 !cursor-not-allowed hover:!opacity-30' : !hasAvailableAddons()}">{{ 'cart.addons' | translate }}</span>
                    </div>
                </div>
                <div *ngIf="!hasCart()">
                    <div class="bg-white rounded-[10px] p-5 ">
                        <p class="font-bold mb-2">{{ 'cart.empty-cart' | translate }}</p>
                        <p *ngIf="!mapOnly">{{ 'cart.to-fixture' | translate }} <a class="text-[var(--red)]" [routerLink]="['/fixtures'] | localize">{{ 'cart.to-fixture-2' | translate }}</a>!</p>
                        <p *ngIf="mapOnly">{{ 'cart.mobile-to-fixture' | translate }}</p>
                    </div>
                </div>
                <div *ngIf="hasCart()">
                    <!-- STEP 1 - TICKETS & SEASON PASSES -->
                    <div *ngIf="step == 1">
                        <!-- TICKETS -->
                        <div class="bg-white rounded-[10px] mb-3" *ngFor="let event of cart.items.events | keyvalue;trackBy:trackByEvent ">
                            <div
                                class="justify-between px-10 py-5 grid grid-cols-[75%_25%] sm:flex sm:flex-col-reverse">
                                <!-- MATCH -->
                                <div class="flex justify-between items-center"
                                    *ngIf="isFixtureTypes(event.value.fixture, fixtureTypes().MATCH)">
                                    <img class=" h-[40px]" [src]="event.value.fixture.home_team.logo"
                                        [alt]="event.value.fixture.home_team.name" />
                                    <div class="px-2">
                                        <p class="text-[15px] font-black text-center">{{
                                            event.value.fixture.home_team.name }} - {{
                                            event.value.fixture.away_team.name }} </p>
                                        <p class="text-sm text-center ">{{ event.value.fixture.venue}}</p>
                                    </div>
                                    <img class="h-[40px]" [src]="event.value.fixture.away_team.logo"
                                        [alt]="event.value.fixture.away_team.name" />
                                </div>
                                <!-- OTHER EVENT -->
                                <div class="flex gap-3 items-center"
                                    *ngIf="isFixtureTypes(event.value.fixture, fixtureTypes().OTHER)">
                                    <img class=" h-[40px]" *ngIf="event.value.fixture.image"
                                        [src]="event.value.fixture.image" [alt]="event.value.fixture.name" />
                                    <div>
                                        <p class="text-[15px] font-black text-left">{{ event.value.fixture.name }} </p>
                                        <p class="text-sm text-left ">{{ event.value.fixture.venue}}</p>
                                    </div>
                                </div>
                                <div class="text-right sm:text-center sm:mb-3">
                                    <p class="text-[13px]">{{ event.value.fixture.competition }}</p>
                                    <p class="text-[13px]">{{ event.value.fixture.formatted_date }}</p>
                                </div>
                            </div>
                            <ul>
                                <app-ticket 
                                    [key]="i" 
                                    [cart]="cart"
                                    *ngFor="let ticket of event.value.tickets | keyvalue;  let last = last; let i = index;trackBy: trackByTicket"
                                    [ticket]="ticket.value" 
                                    [fixtureId]="event.value.fixture.id"
                                    ></app-ticket>
                            </ul>
                        </div>
                        <!-- TICKETS -->

                        <!-- SEASON PASSES -->
                        <div class="bg-white rounded-[10px] mb-3"
                            *ngFor="let seasonPass of cart.items.season_passes| keyvalue;trackBy:trackBySeasonPass ">
                            <div
                                class="justify-between px-10 py-5 grid grid-cols-[75%_25%] sm:flex sm:flex-col-reverse">
                                <div class="flex justify-between items-center">
                                    <div>
                                        <p class="text-[15px] font-black text-left">{{ seasonPass.value.details.name }}
                                        </p>
                                        <p class="text-sm text-left">{{ seasonPass.value.details.subtitle}}</p>
                                    </div>
                                </div>
                            </div>
                            <ul>
                                <app-season-pass [key]="i" [cart]="cart"
                                    *ngFor="let spass of seasonPass.value.season_passes | keyvalue; let last = last; let i = index"
                                    [seasonPass]="spass.value"
                                    [seasonPassSlug]="seasonPass.value.details.slug"></app-season-pass>
                            </ul>
                        </div>
                        <!-- SEASON PASSES -->
                    </div>
                    <!-- STEP 1 - TICKETS & SEASON PASSES -->

                    <!-- STEP 2 ADD-ON -->
                    <div *ngIf="step == 2">
                        <div class=" mb-3">
                            <div class="mb-3" *ngFor="let event of cart.items.events | keyvalue ">

                                <!-- EXTRAS -->
                                <p *ngIf="loadingExtras">{{ 'general.loading' | translate }}</p>

                                <ul *ngIf="!loadingExtras" class="flex flex-col gap-7">
                                    <li *ngFor="let ticket of event.value.tickets | keyvalue; let i = index"
                                        class="bg-white px-10 py-5 rounded-[10px]">
                                        <div
                                            class="justify-between px-10 py-5 w-10/12 mx-auto sm:flex sm:flex-col-reverse sm:px-0 sm:mx-0 sm:w-full">
                                            <!-- MATCH -->
                                            <div class="flex justify-between items-center"
                                                *ngIf="isFixtureTypes(event.value.fixture, fixtureTypes().MATCH)">
                                                <img class=" h-[40px]" [src]="event.value.fixture.home_team.logo"
                                                    [alt]="event.value.fixture.home_team.name" />
                                                <div class="px-2">
                                                    <p class="text-[15px] font-black text-center">{{
                                                        event.value.fixture.home_team.name }} - {{
                                                        event.value.fixture.away_team.name }} </p>
                                                    <p class="text-[13px] text-center">{{
                                                        event.value.fixture.formatted_date }} ({{
                                                        event.value.fixture.competition }})</p>
                                                </div>
                                                <img class="h-[40px]" [src]="event.value.fixture.away_team.logo"
                                                    [alt]="event.value.fixture.away_team.name" />
                                            </div>
                                            <!-- OTHER EVENT -->
                                            <div class="flex gap-3 items-center"
                                                *ngIf="isFixtureTypes(event.value.fixture, fixtureTypes().OTHER)">
                                                <img class=" h-[40px]" *ngIf="event.value.fixture.image"
                                                    [src]="event.value.fixture.image"
                                                    [alt]="event.value.fixture.name" />
                                                <div>
                                                    <p class="text-[15px] font-black text-left">{{
                                                        event.value.fixture.name }}
                                                    </p>
                                                    <p class="text-sm text-left ">{{ event.value.fixture.venue}}</p>
                                                </div>
                                            </div>
                                            <div class="text-right sm:text-center sm:mb-3">

                                            </div>
                                        </div>

                                        <p class="font-bold">{{ ticket.value.title }} - <span
                                                class="font-normal text-[11px] text-[#888888]">{{
                                                ticket.value.details.personal_details.short }}</span></p>
                                        <ul class="divide-y" *ngIf="ticket.value?.add_ons?.length">
                                            <app-addon [addon]="addon" [ticket]="ticket.value"
                                                [fixture]="event.value.fixture" [last]="l"
                                                *ngFor="let addon of ticket.value.add_ons; let j = index; let l = last"></app-addon>
                                        </ul>

                                        <p *ngIf="!ticket.value?.add_ons?.length"
                                            class="text-[12px] mt-5 text-[#888888] text-center">
                                            {{ 'cart.no-available-extra' | translate }}</p>
                                    </li>
                                </ul>
                                <!-- EXTRAS -->
                            </div>
                        </div>
                    </div>
                    <!-- STEP 2 ADD-ON -->

                    <!-- FLEX TICKETS -->
                    <div class="bg-white rounded-[10px] mb-3"
                        *ngFor="let flexTickets of cart.items.flex_tickets | keyvalue ">
                        <div class="justify-between px-10 py-5 grid grid-cols-[75%_25%] sm:flex sm:flex-col-reverse">
                            <div class="flex justify-between items-center">
                                <div>
                                    <p class="text-[15px] font-black text-left mb-1">{{ flexTickets.value.details.title
                                        }}</p>
                                    <p class="text-sm text-left mb-1">{{ flexTickets.value.details.description}}</p>
                                    <p class="text-xs text-left text-gray-400">{{ 'flex.selected-matches' | translate
                                        }}: {{ getFirstOpponent(flexTickets) }}</p>
                                </div>
                            </div>
                        </div>
                        <ul>
                            <app-flex-ticket [key]="i" [cart]="cart"
                                *ngFor="let flexTicket of flexTickets.value.flex_tickets | keyvalue;  let last = last; let i = index"
                                [flexTicket]="flexTicket.value" [fixtureIds]="flexTicket.value.fixture_ids"
                                [flexUuid]="flexTickets.value.details.uuid"></app-flex-ticket>
                        </ul>
                    </div>
                    <!-- FLEX TICKETS -->

                    <ng-container *ngIf="cart.need_to_select_payment_provider">
                        <h2 class="text-white text-[15px] font-bold mb-5 mt-5">{{ 'cart.payment-options' | translate }}</h2>
                        <div class="bg-white rounded-[10px] p-5">
                            <app-input-error-msg customCssClasses="pl-5 mb-4" [show]="isFieldInvalid('paymentMethod', 'required')" [errorMsg]="'cart.payment-option-required' | translate"></app-input-error-msg>
                            <div class="flex flex-col gap-5">
                                <ng-container *ngIf="!isCashier()">
                                    <!-- CUSTOMER -->
                                     <!-- SIMPLE -->
                                    <app-radiobutton 
                                        *ngIf="availablePaymentProviders?.includes('simple_pay')"
                                        type="radio" [currentValue]="getValue('paymentMethod')"
                                        (inputValueChangedEvent)="setValue('paymentMethod', $event)"
                                        labelCssClasses="!text-black !text-[13px]" value="simple_pay"
                                        identifier="simple_pay" type="radio"
                                        [label]="'cart.simplepay-terms' | translate" />
                                    <!-- SIMPLE -->

                                    <!-- STRIPE -->
                                    <app-radiobutton type="radio" [currentValue]="getValue('paymentMethod')"
                                        *ngIf="availablePaymentProviders?.includes('stripe')"
                                        (inputValueChangedEvent)="setValue('paymentMethod', $event)"
                                        labelCssClasses="!text-black !text-[13px]" 
                                        value="stripe"
                                        identifier="stripe" type="radio"
                                        [label]="'cart.stripe-terms' | translate" />
                                    <!-- STRIPE -->

                                    <!-- CUSTOMER -->
                                </ng-container>
                                <ng-container *ngIf="isCashier()">
                                    <!-- CASHIER -->
                                    <div >
                                        <app-radiobutton 
                                            type="radio" 
                                            [currentValue]="getValue('paymentMethod')" 
                                            (inputValueChangedEvent)="setValue('paymentMethod', $event)" 
                                            labelCssClasses="!text-black !text-[13px]" 
                                            value="cash" 
                                            identifier="cash" 
                                            type="radio" 
                                            [label]="'cart.cash' | translate">
                                        </app-radiobutton>
                                    </div>
                                    <div >
                                        <app-radiobutton 
                                            type="radio" 
                                            [currentValue]="getValue('paymentMethod')" 
                                            (inputValueChangedEvent)="setValue('paymentMethod', $event)" 
                                            labelCssClasses="!text-black !text-[13px]" 
                                            value="card" 
                                            identifier="card" 
                                            type="radio" 
                                            [label]="'cart.card' | translate">
                                        </app-radiobutton>
                                    </div>
                                    <div >
                                        <app-radiobutton 
                                            type="radio" 
                                            [currentValue]="getValue('paymentMethod')" 
                                            (inputValueChangedEvent)="setValue('paymentMethod', $event)" 
                                            labelCssClasses="!text-black !text-[13px]" 
                                            value="bank_transfer" 
                                            identifier="bank_transfer" 
                                            type="radio" 
                                            [label]="'cart.bank_transfer' | translate">
                                        </app-radiobutton>
                                    </div>
                                    <div>
                                        <app-radiobutton type="radio" [currentValue]="getValue('paymentMethod')"
                                            (inputValueChangedEvent)="setValue('paymentMethod', $event)"
                                            labelCssClasses="!text-black !text-[13px]"
                                            value="without_proof_exchange_ticket"
                                            identifier="without_proof_exchange_ticket" type="radio"
                                            [label]="'cart.without_proof_exchange_ticket' | translate">
                                        </app-radiobutton>
                                    </div>
                                    <!-- CASHIER -->
                                </ng-container>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="w-[30%] md:w-full">
                <div *ngIf="!auth.isUserLoggedIn">
                    <h2 class="text-white text-[15px] font-bold mb-5">{{ 'cart.login' | translate }}</h2>
                    <div class="bg-white rounded-[10px] pt-8 px-10 pb-5">
                        <h3 class="font-bold">{{ 'cart.not-logged-in' | translate }}</h3>
                        <p class="text-[#888888] text-[13px] mb-7">{{ 'cart.login-to-proceed' | translate }}</p>
                        <app-login-register-content></app-login-register-content>
                    </div>
                </div>
                <div *ngIf="auth.isUserLoggedIn && hasCart()">
                    <h2 class="text-white text-[15px] font-bold mb-5">{{ 'cart.summary' | translate }}</h2>
                    <div class="bg-white rounded-[10px] pt-8 px-10 pb-5" [ngClass]="{'mb-10' : mapOnly}">
                        <p class="font-bold mb-4 flex gap-3 items-center"><app-wallet></app-wallet> {{ auth.user.email }}</p>   
                        <p class="text-[#888888] text-[13px] mb-5 leading-4">{{ 'cart.tickets-will-be-available-via-app' | translate }}</p>
                        <div *ngIf="lastStep > 1">
                            <p class="text-[11px] text-[#888888] flex justify-between">{{
                                'cart.tickets-season-passes-amount' | translate }}: <span>{{ cart.tickets_price
                                    }}</span></p>
                            <p class="text-[11px] text-[#888888] flex justify-between">{{ 'cart.extra-amount' |
                                translate }}: <span>{{ cart.addons_price }}</span></p>
                            <hr class="my-[5px]" />
                        </div>
                        <p class="text-[13px] font-bold flex justify-between mb-5">{{ 'cart.amount_to_pay' | translate }}: <span>{{ cart.total_price }}</span></p>   
                        <p class="text-[13px] font-bold mb-5">{{ 'cart.invoice-details' | translate }} / <button class="text-[var(--red)]" type="button" (click)="showInvoiceDetailsModal()">{{ 'general.edit' | translate }}</button></p>   
                        <p *ngIf="!invoiceDetails" class="text-[#888888] text-[13px] mb-5 leading-4">{{ 'cart.please-fill-invoice-details' | translate }}</p>   
                        <div *ngIf="invoiceDetails" class="mb-8">
                            <p class="text-[#888888] text-[13px]" *ngFor="let row of invoiceDetailsFormatted">{{ row }}
                            </p>
                        </div>
                        <!-- NEXT -->
                        <app-button type="button" *ngIf="!isLastStep() && hasAvailableAddons()" (click)="toNextStep()"
                            [isProcessing]="isRequesting">{{ 'general.next' | translate }}</app-button>
                        <!-- NEXT -->

                        <!-- SUBMIT -->
                        <app-button *ngIf="isLastStep() || !hasAvailableAddons()" [isProcessing]="isRequesting">{{
                            actionButtonText
                            }}</app-button>
                        <!-- SUBMIT -->
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>