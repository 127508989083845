<!-- LOADING -->
<div *ngIf="loadingCountries || loading" class="py-4">
    <p class="text-center text-sm">{{ 'general.loading-personal-details' | translate }}</p>
</div>
<!-- LOADING -->

<p *ngIf="error" class="text-[var(--red)] text-center mb-6 break-words mt-6" [innerHTML]="error"></p>

<ng-container *ngIf="!loading && !loadingCountries">
    <div class="mt-6 mx-auto w-[100%] h-[4px] bg-[length:18px_2px] bg-top bg-repeat-x bg-[linear-gradient(to_right,#eaeaea_50%,transparent_50%)]"></div>
    <div class="flex gap-2 mt-4 justify-between">
        <p class="font-bold text-sm text-[var(--primary-color)]">Jegytulajdonos adatai
        </p>
        <p *ngIf="saved" class="block font-bold text-sm text-[#9a9a9a]">
            {{ 'general.saved-successfully' | translate }}
        </p>
    </div>
    <!-- FORM -->
    <form [formGroup]="personalDetailsForm" (ngSubmit)="onSubmit()" class="mt-3" 
        [ngClass]="{'is-requesting' : isRequesting}">
        <div class="grid grid-cols-2 gap-5 mb-5 sm:grid-cols-1">
            <div *ngFor="let input of personalDetailsRequirements">
                <label class="mb-1 text-xs text-[#a7a7a7]">{{ input.label }}: <span *ngIf="input.required">*</span></label>

                <!-- INPUT -->
                <app-input *ngIf="input.type == 'text' || input.type == 'date'" 
                    [required]="input.required"
                    [value]="ticketPersonalDetails ? ticketPersonalDetails[input.name] : ''"
                    [customCssClasses]="isFieldInvalidAtAll(input.name) ? 'border border-[var(--red)]' : ''"
                    [placeholder]="input.label" 
                    [type]="input.type"
                    [disabled]="isRequesting"
                    (inputValueChangedEvent)="setValue(input.name, $event)"
                    (inputBlurEvent)="onInputBlur(input.name)">
                    ></app-input>

                <!-- SELECT -->
                <ng-select 
                   [class]="{'custom': true, 'disabled' : isRequesting}"
                    notFoundText="" 
                    *ngIf="isSelect(input.type) && input.name == 'birth_country'"
                    [items]="countries" 
                    #select 
                    [virtualScroll]="false" 
                    (change)="setValue(input.name, $event);onInputBlur(input.name)"
                    [formControlName]="input.name" 
                    bindLabel="label" 
                    [readonly]="isRequesting"
                    bindValue="id"
                    (blur)="onInputBlur(input.name)"
                    [compareWith]="compareCountry"></ng-select>

                <app-input-error-msg *ngIf="input.required" customCssClasses="pl-4"
                    [show]="isFieldInvalid(input.name, 'required')" [errorMsg]="input.required_text"></app-input-error-msg>
            </div>
            <div class="flex items-end">
                <app-button classList="!bg-[var(--primary-color)] h-[50px]"  
                    class="!w-full"
                    *ngIf="!isError(cartErrors().CART_NOT_FOUND)" 
                    [isProcessing]="isRequesting">
                    {{ saved ? ('general.saved-successfully' | translate) : ('general.save' | translate) }}</app-button>
            </div>
        </div>
      
    </form>
    <!-- FORM -->

</ng-container>