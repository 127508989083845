import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { clearCart, setCart } from 'src/app/actions/cart.actions';
import { removeSpecificSoldSeat, seatDeleted, seatDeleting, setSpecificSoldSeatToTrue } from 'src/app/actions/seats.actions';
import { ApiRequestService } from '../ApiRequest/api-request.service';
import { EncryptDecryptService } from '../EncryptDecryptService/encrypt-decrypt.service';
import { PurchaseType } from 'src/app/enums/stadium/purchase-type';
import { CheckoutSettings } from 'src/app/interfaces/Class/Cart/CheckoutSettings';
import { BookSeatData } from 'src/app/interfaces/State/Seat/book-seat-data';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  constructor(
    private _apiRequestService: ApiRequestService,
    private _store: Store,
    private _encryptDecryptService: EncryptDecryptService,
  ) { }

  public async bookSeat(
      seatId: any, 
      entityId: string, 
      purchaseType: PurchaseType = PurchaseType.TICKET, 
      data?: BookSeatData,
    ) {
    try {
      let res;
      if (purchaseType == PurchaseType.TICKET) {
        res = await this._apiRequestService.postRequest('fixtures/' + entityId + '/book/' + seatId)
      } else if (purchaseType == PurchaseType.SEASON_PASS) {
        res = await this._apiRequestService.postRequest('season-passes/' + entityId + '/book/' + seatId)
      } else if (purchaseType == PurchaseType.FLEX) {
        res = await this._apiRequestService.postRequest('flex/' + entityId + '/book/' + seatId, {selected_fixtures: data?.selectedFixtures})
      }
      this.saveCartIdToLocaleStorage(res.data.cart.id)
      this._store.dispatch(setCart({ cart: res.data.cart }))
      this._store.dispatch(setSpecificSoldSeatToTrue({ seatId: seatId }))
      return res
    } catch (e: any) {
      return e.error
    }
  }

  public async removeSeat(seatId: any, entityId: string, purchaseType: PurchaseType = PurchaseType.TICKET, data?: BookSeatData) {
    try {
      this._store.dispatch(seatDeleting({ seatId: data?.seatId ?? seatId }))
      let res;
      if (purchaseType == PurchaseType.TICKET) {
        res = await this._apiRequestService.deleteRequest('fixtures/' + entityId + '/remove/' + seatId)
      } else if (purchaseType == PurchaseType.SEASON_PASS) {
        res = await this._apiRequestService.deleteRequest('season-passes/' + entityId + '/remove/' + seatId)
      } else if (purchaseType == PurchaseType.FLEX) {
        const flexTicketPurchaseUuid = seatId
        res = await this._apiRequestService.postRequest('flex/' + entityId + '/remove/' + flexTicketPurchaseUuid, {selected_fixtures: data?.selectedFixtures})
      }
      
      this._store.dispatch(setCart({ cart: res.data.cart }))
      this._store.dispatch(seatDeleted({ seatId: data?.seatId ?? seatId }))
      this._store.dispatch(removeSpecificSoldSeat({ seatId: data?.seatId ?? seatId }))
      return res
    } catch (e: any) {
      return e.error
    }
  }

  public async bookRandomSeatForSector(sectorId: string, entityId: string, purchaseType: PurchaseType = PurchaseType.TICKET) {
    try {
      let res;
      if (purchaseType == PurchaseType.TICKET) {
        res = await this._apiRequestService.postRequest('fixtures/' + entityId + '/book/sector/' + sectorId)
      } /*else if (purchaseType == PurchaseType.SEASON_PASS){
       res = await this._apiRequestService.postRequest('season-passes/' + entityId + '/book/' + seatId)
      }*/
      this.saveCartIdToLocaleStorage(res.data.cart.id)
      this._store.dispatch(setCart({ cart: res.data.cart }))
      return res
    } catch (e: any) {
      return e
    }
  }

  public async bookRandomSeatForSectorWithTicketType(sectorId: string, entityId: string, ticketTypeId: number) {
    try {
      let res;
      res = await this._apiRequestService.postRequest('fixtures/' + entityId + '/book/sector/' + sectorId + '/ticket-type/' + ticketTypeId)

      this.saveCartIdToLocaleStorage(res.data.cart.id)
      this._store.dispatch(setCart({ cart: res.data.cart }))

      return res
    } catch (e: any) {
      return e
    }
  }

  public async addAddOn(seatId: number, fixtureSlug: string, addonUuid: string) {
    try {
      let res = await this._apiRequestService.postRequest('cart/add-ons/add', {seat: seatId, fixture: fixtureSlug, addon: addonUuid })
      this.saveCartIdToLocaleStorage(res.data.cart.id)
      this._store.dispatch(setCart({ cart: res.data.cart }))
      return res
    } catch (e: any) {
      return e.error
    }
  }

  public async removeAddOn(seatId: number, fixtureSlug: string, addonUuid: string) {
    try {
      let res = await this._apiRequestService.postRequest('cart/add-ons/remove', {seat: seatId, fixture: fixtureSlug, addon: addonUuid })
      this.saveCartIdToLocaleStorage(res.data.cart.id)
      this._store.dispatch(setCart({ cart: res.data.cart }))
      return res
    } catch (e: any) {
      return e.error
    }
  }

  public async setInvoiceDetails(controls: any) {
    try {
      let invoiceDetails: { [key: string]: string } = {}
      Object.keys(controls).map((key: string) => {
        if (controls[key].value != "" && typeof controls[key].value !== 'object' && !Number.isInteger(controls[key].value)) {
          invoiceDetails[key] = this._encryptDecryptService.encrypt(controls[key].value)
        } else if (controls[key].value != "" && typeof controls[key].value === 'object') {
          invoiceDetails[key] = controls[key].value.id
        } else if (controls[key].value != "" && Number.isInteger(controls[key].value)) {
          invoiceDetails[key] = controls[key].value
        }
      })

      const res = await this._apiRequestService.putRequest('cart/invoice-details', invoiceDetails);

      this._store.dispatch(setCart({ cart: res.data.cart }))

      return true;
    } catch (e: any) {
      return e.error.error
    }
  }

  public async setPersonalDetails(fixtureId: any, ticketId: any, controls: any, purchaseType: PurchaseType = PurchaseType.TICKET) {
    try {
      let personalDetails: { [key: string]: string } = {}

      Object.keys(controls).map((key: string) => {
        if (controls[key].value != "" && typeof controls[key].value === 'object') {
          personalDetails[key] = controls[key].value?.id ?? controls[key].value
        } else {
          personalDetails[key] = controls[key].value
        }

      })

      let res

      if (purchaseType == PurchaseType.TICKET) {
        res = await this._apiRequestService.putRequest('cart/personal-details/ticket/' + fixtureId + '/' + ticketId, personalDetails);
      } else if (purchaseType == PurchaseType.SEASON_PASS) {
        res = await this._apiRequestService.putRequest('cart/personal-details/season-pass/' + fixtureId + '/' + ticketId, personalDetails);
      } else if (purchaseType == PurchaseType.FLEX) {
        res = await this._apiRequestService.putRequest('cart/personal-details/flex/' + fixtureId + '/' + ticketId, personalDetails);
      }

      this._store.dispatch(setCart({ cart: res.data.cart }))

      return true;
    } catch (e: any) {
      return e.error
    }
  }

  public saveCartIdToLocaleStorage(cartId: string) {
    localStorage.setItem('cartId', cartId)
  }


  public async updateCartTicketType(seatId: number, fixtureId: string, ticketTypeId: number) {
    try {
      const res = await this._apiRequestService.putRequest('cart/seat/' + seatId + '/ticket-type', {
        fixtureId: fixtureId,
        ticketTypeId: ticketTypeId,
      })
      this.saveCartIdToLocaleStorage(res.data.cart.id)
      this._store.dispatch(setCart({ cart: res.data.cart }))
      return res
    } catch (e: any) {
      return e.error
    }
  }

  public async updateSeasonPassTicketType(seatId: number, seasonPassSlug: string, ticketTypeId: number) {
    try {
      const res = await this._apiRequestService.putRequest('cart/season-pass/' + seatId + '/ticket-type', {
        seasonPassSlug: seasonPassSlug,
        ticketTypeId: ticketTypeId,
      })
      this.saveCartIdToLocaleStorage(res.data.cart.id)
      this._store.dispatch(setCart({ cart: res.data.cart }))
      return res
    } catch (e: any) {
      return e.error
    }
  }

  public async updateFlexTicketType(flexTicketUuid: string, flexRuleUuid: string, ticketTypeId: number) {
    try {
      const res = await this._apiRequestService.putRequest('cart/flex/' + flexTicketUuid + '/ticket-type', {
        flexRuleUuid: flexRuleUuid,
        ticketTypeId: ticketTypeId,
      })
      this.saveCartIdToLocaleStorage(res.data.cart.id)
      this._store.dispatch(setCart({ cart: res.data.cart }))
      return res
    } catch (e: any) {
      return e.error
    }
  }

  public async checkout(paymentMethod: string, settings?: CheckoutSettings) {
    try {
      let appendToUrl = ''

      if (settings && settings.onlyMap) {
        appendToUrl = '?only-map=1'
      }

      const res = await this._apiRequestService.postRequest('cart/checkout' + appendToUrl, { payment_provider: paymentMethod });
      return res
    } catch (e: any) {
      return e.error
    }
  }

  public async validateTickets(settings?: CheckoutSettings) {
    try {
      let appendToUrl = ''

      if (settings && settings.onlyMap) {
        appendToUrl = '?only-map=1'
      }

      const res = await this._apiRequestService.postRequest('cart/validate-tickets' + appendToUrl);
      return res
    } catch (e: any) {
      return e.error
    }
  }

  public async getCart() {
    try {
      const cartId = localStorage.getItem('cartId')
      if (cartId) {
        const res = await this._apiRequestService.getRequest('cart')
        if (res.success) {
          this._store.dispatch(setCart({ cart: res.data.cart }))
          localStorage.setItem('cartId', res.data.cart.id)
        }
      }
    } catch (e: any) {
    }
  }

  public clearCart() {
    localStorage.removeItem('cartId')
    this._store.dispatch(clearCart())
  }

  public async removeSameFlexRuleFromCart(flexRuleUuid: string) {
    try {
      const res = await this._apiRequestService.deleteRequest(`cart/flex/remove-same-flex-rule/${flexRuleUuid}`);
      if (res.success) {
        this._store.dispatch(setCart({ cart: res.data.cart }))
        localStorage.setItem('cartId', res.data.cart.id)
      }
      return res
    } catch (e: any) {
      return e.error
    }
  }
}
