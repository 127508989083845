import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { CartError } from 'src/app/enums/cart/errors';
import { PurchaseType } from 'src/app/enums/stadium/purchase-type';
import { Cart } from 'src/app/interfaces/State/Cart/cart';
import { Ticket } from 'src/app/interfaces/State/CartItem/ticket';
import { Modals } from 'src/app/interfaces/State/Modal/modals';
import { AlertService } from 'src/app/services/Alert/alert.service';
import { CartService } from 'src/app/services/Cart/cart.service';
import { CountryService } from 'src/app/services/Misc/Country/country.service';
import { ModalService } from 'src/app/services/Modal/modal.service';
import { Country } from 'src/app/interfaces/Class/Settings/Country';
import { InputTypes } from 'src/app/enums/form/input/inputTypes';
import { TranslateService } from '@ngx-translate/core';
import { SingleTicket } from 'src/app/interfaces/State/MyTickets/singleTicket';
import { PersonalDetails } from 'src/app/interfaces/State/CartItem/personal-details';
import { TicketService } from 'src/app/services/Ticket/ticket.service';
import { MyTicketService } from 'src/app/services/MyTicket/my-ticket.service';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'src/app/components/form/button/button.module';
import { InputModule } from 'src/app/components/form/input/input.module';
import { InputErrorMsgModule } from 'src/app/components/form/error/input-error-msg/input-error-msg.module';
import { XModule } from 'src/app/components/assets/icons/x/x.module';
import { SelectComponent } from 'src/app/components/form/select/select.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';


@Component({
  selector: 'app-ticket-personal-details',
  standalone: true,
  imports: [ CommonModule,
      FormsModule,
      ReactiveFormsModule,
      ButtonModule,
      InputModule,
      InputErrorMsgModule,
      XModule,
      SelectComponent,
      NgSelectModule,
      TranslateModule,],
  templateUrl: './personal-details.component.html',
  styleUrls: ['./personal-details.component.scss']
})
export class PersonalDetailsComponent {
  @Input() fixtureId: string = ''
  @Input() ticketId: number = 0
  @Input() key!: number
  @Input() inputCountries: Array<Country> = []

  public formSubmitAttempt: boolean = false
  public isRequesting: boolean = false
  public error: string = ""
  public error_code? :number
  public cart: Cart = {} as Cart
  public personalDetailsForm  = this._formBuilder.group({}); 
  public modals: Modals = {} as Modals
  public seasonPassSeatId: number = 0 
  public seasonPassSlug: string = ''
  public saved: Boolean = false
 
  private _subscription?: Subscription 
  
  public loading: Boolean = true
  public countries: Array<Country> = []
  public loadingCountries: boolean = false
  public defaultCountry: Country = {} as Country
  public eTicket?: SingleTicket
  public flexRuleUuid?: string
  public flexTicketId?: string
  public flexTicketUuid?: string

  constructor(    
    private _modalService: ModalService,  
    private _store: Store,
    private _formBuilder: FormBuilder,
    private _cartService: CartService,
    private _countryService: CountryService,
    private _alertService: AlertService,
    private _translateService: TranslateService,
    private _myTicketService: MyTicketService,
  ){
    this.countries = this.inputCountries
  }

  async ngOnInit()  {
    this.loading = false

    this._subscription = this._store.subscribe((state: any) => {
      this.cart = state.cart

      this.createFormGroup()
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['inputCountries'] && changes['inputCountries'].currentValue.length && !this.countries.length) {
      this.countries = changes['inputCountries'].currentValue
      this.getCountries();
    }
  }

  ngOnDestroy(): void {
    this._subscription?.unsubscribe()
  }

  private async getCountries() {
    if(!this.countries.length) {
      return
    }

    this.countries.every((country) => {
      if(country.is_default) {
        this.defaultCountry = country
        return false
      }
      return true
    })

    if(this.defaultCountry.id && 
      this.personalDetailsForm.get('birth_country') && 
      (!this.personalDetailsForm.get('birth_country')?.value || this.personalDetailsForm.get('birth_country')?.value == "")
    )
    {
      this.setValue('birth_country', this.defaultCountry.id)
    }
  }

  private createFormGroup() {
    let group: any = {}

    if(!this.personalDetailsRequirements) return 

    Object.keys(this.personalDetailsRequirements).map((key) => {
      const detail = this.personalDetailsRequirements[Number(key)]
      let value = this.ticketPersonalDetails ? this.ticketPersonalDetails[detail.name] : ''

      group[detail.name] = new FormControl(value, detail.required ? Validators.required : null)
    })
    
    this.personalDetailsForm = new FormGroup(group);

    this.personalDetailsForm.valueChanges.subscribe(() => {
      this.personalDetailsForm.markAsDirty()
    });
  }
  
  setValue(property: string, value: any) {
    this.personalDetailsForm.get(property)?.setValue(value)
  }

  getValue(property: string) {
    return this.personalDetailsForm.get(property)?.getRawValue()
  }

  isFieldInvalid(field: string, errorType: string = '') {
    return !this.personalDetailsForm.get(field)!.valid && 
    this.formSubmitAttempt &&
    (errorType == '' || this.personalDetailsForm?.get(field)?.errors?.[errorType])
  }

  isFieldInvalidAtAll(field: string) {
    return !this.personalDetailsForm.get(field)!.valid && this.formSubmitAttempt
  }

  async onSubmit() {
    if(this.isRequesting) return ;

    this.isRequesting = true
    this.formSubmitAttempt = true

    this.error = ""

    /* error */
    if(!this.personalDetailsForm.valid) {
      this.isRequesting = false
      return ;
    }

    let res

    if(this.eTicket?.ticket) {
      res = await this._myTicketService.setPersonalDetails(this.eTicket?.fixture?.slug, this.eTicket?.ticket?.seat_id! ,this.personalDetailsForm.controls, PurchaseType.TICKET)
    } else if(this.fixtureId) {
      res = await this._cartService.setPersonalDetails(this.fixtureId, this.ticketId ,this.personalDetailsForm.controls, PurchaseType.TICKET)
    } else if (this.seasonPassSlug) {
      res = await this._cartService.setPersonalDetails(this.seasonPassSlug, this.seasonPassSeatId ,this.personalDetailsForm.controls, PurchaseType.SEASON_PASS)
    } else if (this.flexRuleUuid) {
      res = await this._cartService.setPersonalDetails(this.flexRuleUuid, this.flexTicketUuid! ,this.personalDetailsForm.controls, PurchaseType.FLEX)
    }

    this.isRequesting = false

    if(res === true) {
      this.handleSaveText()

      return ;
    } 

    this._alertService.error('',res.error, false)
    this.error = res.error
    this.error_code = res.status
  }

  public isError(cartError: CartError)
  {
    return this.error_code == cartError
  }

  public cartErrors()
  {
    return CartError;
  }

  get personalDetailsRequirements() {
    if(this.eTicket?.ticket) {
      return this.eTicket?.ticket?.requirements!.personal_details
    }

    if(this.fixtureId) {
      return this.cart.items?.events[this.fixtureId]?.tickets[this.ticketId]?.requirements?.personal_details
    } else if(this.seasonPassSlug) {
      return this.cart.items?.season_passes[this.seasonPassSlug]?.season_passes[this.seasonPassSeatId]?.requirements?.personal_details
    } 

    return this.cart.items?.flex_tickets[this.flexRuleUuid!]?.flex_tickets[this.flexTicketId!]?.requirements?.personal_details
  }

  get ticketPersonalDetails() {
    if(this.eTicket?.ticket) {
      return this.eTicket.ticket.personal_details.array as unknown as PersonalDetails
    }

    if(this.fixtureId) {
      return this.cart.items?.events[this.fixtureId]?.tickets[this.ticketId]?.details?.personal_details.array
    } else if(this.seasonPassSlug) {
      return this.cart.items?.season_passes[this.seasonPassSlug]?.season_passes[this.seasonPassSeatId]?.details.personal_details?.array
    } else if(this.flexRuleUuid) {
      return this.cart.items?.flex_tickets[this.flexRuleUuid]?.flex_tickets[this.flexTicketId!]?.details.personal_details?.array
    }

    return ''
  }

  get ticketTitle() {
    if(this.eTicket?.ticket) {
      return this.eTicket.ticket.title
    }

    if(this.fixtureId) {
      return this.cart.items?.events[this.fixtureId]?.tickets[this.ticketId]?.title
    } else if(this.seasonPassSlug) {
      return this.cart.items?.season_passes[this.seasonPassSlug]?.season_passes[this.seasonPassSeatId]?.title
    } else if(this.flexRuleUuid) {
      return this.cart.items?.flex_tickets[this.flexRuleUuid]?.flex_tickets[this.flexTicketId!]?.title
    }

    return ''
  }

  isSelect(inputType: string) {
    return inputType == InputTypes.SELECT
  }

  compareCountry(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.id === c2 : c1 === c2;
  }

  onInputBlur(fieldName: string) {
    /* if not valid, nothing happens */
    if(!this.personalDetailsForm.valid) {
      this.isRequesting = false
      return ;
    }

    /* check if anything has been changed */
    if(!this.personalDetailsForm.dirty) {
      this.isRequesting = false
      return ;
    }

    /* submit */
    this.onSubmit()
  }
  
  public handleSaveText() {
    this.saved = true
    setTimeout(() => {
      this.saved = false
    }, 3000)
  }
}
